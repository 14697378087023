/* set light grey color as footer text & link color*/
.team-name-container,
.snFooterContainer {
  background-color: $primary-color-alt;
}
/* set blue color as footer footer HR color*/

.custom-footer hr {
  background-color: $primary-color;
}
/* set blue color as footer text & link color*/
.custom-footer .ajax.footer .textBlockElement ul li,
.custom-footer .footer-content .textBlockElement ul li,
.custom-footer .ajax.footer .textBlockElement ul li a,
.custom-footer .footer-content .textBlockElement ul li a,
.custom-footer .ajax.footer .textBlockElement ul li p,
.custom-footer .footer-content .textBlockElement p,
.uslLegal ul li a,
#siteFooter > ul li,
#siteFooter > ul li a {
  color: $primary-color;  
}
/* set blue color as headline text color */
.team-name-container {
  color: $primary-color;
}